import dynamic from 'next/dynamic'
import Head from 'next/head'
import { descripion } from '../constants/seo'
import LoadingComponent from '../components/shared/loadingComponent'

const LoginFormComponent = dynamic(() => import('../components/pagesCpmponents/loginSignUpComponents/loginFormComponent'), {
    loading: () => <LoadingComponent />,
})
const PagaeLoad = dynamic(() => import('../components/animations/pageLoad'))


export default function LoginPage() {

    const title = 'Log In | Fl Gold Coin Exchange'
    
    return (<>
        <Head>
            <title>{title}</title>
            <meta name="description" content={descripion} />
            <link rel="canonical" href={`${process.env.siteUrl}/login`} />
            <meta property="og:url" content={`${process.env.siteUrl}/login`} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={descripion} />
            <meta property="og:image" content={`${process.env.siteUrl}/images/facebook.jpg`} />
            <meta name="AdsBot-Google" content="noindex" />
            <meta name="robots" content="noindex, nofollow" />
        </Head>
        <PagaeLoad>
            <LoginFormComponent />
        </PagaeLoad>
    </>)
}